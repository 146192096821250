<script>
import Layout from '@layouts/main'

import { setComputed } from '@state/helpers'

export default {
  components: { Layout },
  props: {
    brandData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {

    }
  },
  computed: {
    ...setComputed,
  },
  methods: {
    getExtrafields(item, field) {
      if(field in item.extrafields){
        return item.extrafields[field].value;
      }else{
        return false
      }
    },
    formatNtoBr(text) {
      return text.replace(/\r\n/g,"<br>").replace(/\n/g,"<br>");
    },
    checkPhoneOrUrl(text) {
      return /^[A-Za-z0-9 -]*$/.test(text) ? 'phone' : 'url';
    }
  }
}
</script>
<template>
  <Layout>
    <div>
      <section
        :style="{ backgroundImage: `url(${ getExtrafields(brandData, 'brand_banner_image')})` }"
        :class="$style.banner"
      >
      </section>
      <section
        :class="$style.sectionA"
        :style="{
        background: getExtrafields(brandData, 'brand_banner_back_color'),
        color: getExtrafields(brandData, 'brand_banner_font_color'),
       }" class="section">
        <div class="container">
          <h2 v-fade-up :class="$style.title">{{ getExtrafields(brandData, 'brand_banner_title') }}</h2>
          <p
            v-fade-up
            :class="$style.content"
            v-html="formatNtoBr(getExtrafields(brandData, 'brand_banner_content'))"></p>
        </div>
      </section>
      <section :class="$style.sectionB" class="section">
        <div class="container">
          <h2 v-fade-up :class="$style.title">{{ brandData.title }}</h2>
          <p v-fade-up :class="$style.content" v-html="formatNtoBr(getExtrafields(brandData, 'brand_description'))"></p>
        </div>
      </section>
      <section :class="$style.sectionC" class="section">
        <div :class="$style.container">
          <div :class="$style.gridBox">
            <div>
              <div v-fade-up class="row flex-row-reverse" :class="$style.imageList">
                <div :class="[$style.textBox, $style.imageItem , $style.imageItemA]">
                  <div :class="$style.contentBox">
                    <div :class="$style.contentBoxInner">
                      <!--<img alt="標題" :class="$style.titleImage" :src="getExtrafields(brandData, 'first_row_title_image')">-->
                      <div v-if="getExtrafields(brandData, 'first_row_title_image')" :class="$style.title">{{ getExtrafields(brandData, 'first_row_title_image') }}</div>
                      <p :class="$style.content"
                         v-html="formatNtoBr(getExtrafields(brandData, 'first_row_image_content'))"></p>
                    </div>
                  </div>
                </div>
                <div :class="[$style.imgBox, $style.imageItem , $style.imageItemB]">
                  <div :style="{ backgroundImage: `url(${ getExtrafields(brandData, 'first_row_image')})` }" :class="[$style.img, $style.imgA]"></div>
                </div>
              </div>
            </div>
            <div class="row" :class="$style.imageList">
              <div v-fade-up :class="[$style.imageItem, $style.imageItemC]">
                <div :style="{ backgroundImage: `url(${ getExtrafields(brandData, 'second_row_left_image') })` }"
                     :class="[$style.img, $style.imgB]"></div>
              </div>
              <div v-fade-up="{ delay: 3 }" :class="[$style.imageItem, $style.imageItemD]">
                <div :style="{ backgroundImage: `url(${ getExtrafields(brandData, 'second_row_right_image') })` }"
                     :class="[$style.img, $style.imgC]"></div>
              </div>
              <div class="col-12 col-lg-2"></div>
            </div>
            <div class="row" :class="$style.imageList">
              <div class="col-lg-2" :class="[$style.imageItem, $style.imageItemE]"></div>
              <div v-fade-up :class="[$style.imageItem, $style.imageItemF]">
                <div :style="{ backgroundImage: `url(${ getExtrafields(brandData, 'third_row_image') })` }"
                     :class="[$style.img, $style.imgD]"></div>
              </div>
            </div>
            <div class="row" :class="$style.imageList">
              <div v-fade-up :class="[$style.imageItem, $style.imageItemG]">
                <div :style="{ backgroundImage: `url(${ getExtrafields(brandData, 'fourth_row_left_image') })` }"
                     :class="[$style.img, $style.imgE]"></div>
              </div>
              <div v-fade-up="{ delay: 3 }" :class="[$style.imageItem, $style.imageItemH]">
                <div :style="{ backgroundImage: `url(${ getExtrafields(brandData, 'fourth_row_right_image') })` }"
                     :class="[$style.img, $style.imgF]"></div>
              </div>
            </div>
          </div>
          <div class="text-center">
            <div>
              <a v-fade-up
                 target="_blank"
                 v-if="getExtrafields(brandData, 'official_link')"
                 :href="getExtrafields(brandData, 'official_link')"
                 :class="$style.btnOutline"
              >
                {{$t("global.BRAND_OFFICIAL")}}
              </a>
              <a v-fade-up="{ delay: 3 }"
                 target="_blank"
                 v-if="getExtrafields(brandData, 'facebook_link')"
                 :href="getExtrafields(brandData, 'facebook_link')"
                 style="letter-spacing: 2px"
                 :class="$style.btnOutline"
              >
                Facebook
              </a>
              <template v-if="getExtrafields(brandData, 'brand_booking_link')">
                <a
                  v-if="checkPhoneOrUrl(getExtrafields(brandData, 'brand_booking_link')) === 'url'"
                  v-fade-up="{ delay: 6 }"
                  target="_blank"
                  :href="getExtrafields(brandData, 'brand_booking_link')"
                  :class="$style.btnOutline"
                >
                  {{$t("global.BRAND_BOOK_NOW")}}
                </a>
                <a
                  v-if="checkPhoneOrUrl(getExtrafields(brandData, 'brand_booking_link')) === 'phone'"
                  v-fade-up="{ delay: 6 }"
                  :href="`tel:${getExtrafields(brandData, 'brand_booking_link')}`"
                  :class="$style.btnOutline"
                >
                  {{$t("global.BRAND_BOOK_NOW")}}
                </a>
              </template>

            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
</template>
<style lang="scss" module>
  .banner {
    height: calc(100vh - 130px);
    background-size: cover;
    background-position: center;
    @include touch {
      height: calc(100vh - 70px);
    }
  }
  .gridBox {
    margin-bottom: 142px;
    @include touch {
      margin-bottom: 40px;
    }
  }
  .sectionA {
    padding: 68px 0;
    text-align: center;
    font-family: Palatino, 'Noto Serif TC', serif;
    @include touch {
      padding: 30px 0;
    }
    .title {
      @include font(32px, 62px, 9.6px, 500);
      margin-bottom: 24px;
      @include touch {
        @include font(28px, null, 8.4px, null);
        text-indent: 10px;
        margin-bottom: 6.5px;
      }
    }
    .content {
      @include font(14px, 26px, 1.12px, 500);
      margin-bottom: 0;
    }

  }

  .sectionB {
    padding: 128px 0 136px;
    text-align: center;
    font-family: 'Noto Serif TC', serif;
    background: #f5f1ed;
    @include touch {
      padding: 70px 0;
    }
    .title {
      @include font(44px, 65px, 2.64px, 500);
      font-family: Palatino, 'Noto Serif TC', serif;
      margin-bottom: 120px;
      @include touch {
        @include font(30px, 55px, null, null);
        margin-bottom: 40px;
      }
    }
    .content {
      @include font(16px, 28px, 2px, 500);
      width: 73%;
      margin: 0 auto;
      text-align: left;
      @include touch {
        @include font(14px, null, 1.68px, null);
        text-align: left;
        width: 100%;
      }
      p {
        margin-bottom: 0;
      }
    }
  }

  .sectionC {
    padding: 0 0 253px;
    background: #f5f1ed;
    @include touch {
      padding: 0 0 28px;
    }
    .container {
      @include container(1288px);
    }

    .img {
      height: 100%;
      background-position: center;
      background-size: cover;
      @include touch {
        height: 205px;
      }
    }
    .imgBox {
      -webkit-box-flex: 0;
      flex: 0 0 62.889%;
      max-width: 62.889%;
      position: relative;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      @include touch {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    .textBox {
      font-family: 'Noto Serif TC', serif;
      background: #ece8de;
      margin-left: -20px;
      margin-right: 20px;
      -webkit-box-flex: 0;
      flex: 0 0 37.111%;
      max-width: 37.111%;
      position: relative;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      @include touch {
        flex: 0 0 100%;
        max-width: 100%;
        margin-left: 0;
        margin-right: 0;
        background: transparent;
        // margin-left: 20px;
        // margin-right: 20px;
      }
      .contentBox {
        padding: 3rem 15px;
        display: flex;
        justify-content: center;
        @include touch {
          background: #ece8de;
          padding: 60px 20px 56px;
        }
        & > div {
          // width: 82%;
          @include touch {
            // width: 100%;
            // padding: 0 15px;
          }
        }
      }

      .contentBoxInner {
        display: flex;
        flex-direction: column;
        width: 365px;
        @include touch {
          width: 100%;
        }
      }

      .title {
        @include font(28px, null, 5.1px, null);
        margin-bottom: 45px;
        text-align: left;
        @include touch {
          @include font(22px, null, 5.1px, null);
          margin-bottom: 20px;
        }
      }
      .titleImage {
        max-width: 100%;
        margin-bottom: 45px;
      }
      .content {
        @include font(14px, 25px, 2.66px, 500);
        margin-bottom: 0;
        // width: 334px;
        max-width: 100vw;
        width: 100%;
        // margin: auto;
        @include touch {
          width: 100%;
        }
      }
    }
  }
  .imageList {
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 40px;
    @include touch {
      margin-left: -4px;
      margin-right: -4px;
      margin-bottom: 8px;
    }
    .imageItem {
      padding-left: 20px;
      padding-right: 20px;
      &.imageItemC {
        position: relative;
        -webkit-box-flex: 0;
        flex: 0 0 32.998%;
        max-width: 32.998%;
        @include touch {
          flex: 0 0 41.6666666667%;
          max-width: 41.6666666667%;
        }
      }
      &.imageItemD {
        position: relative;
        -webkit-box-flex: 0;
        flex: 0 0 45.808%;
        max-width: 45.808%;
        @include touch {
          flex: 0 0 58.3333333333%;
          max-width: 58.3333333333%;
        }
      }
      &.imageItemE {
        position: relative;
        -webkit-box-flex: 0;
        flex: 0 0 23.136%;
        max-width: 23.136%;
        @include touch {
          flex: 0 0 0;
          max-width: 0;
        }
      }
      &.imageItemF {
        position: relative;
        -webkit-box-flex: 0;
        flex: 0 0 76.864%;
        max-width: 76.864%;
        @include touch {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
      &.imageItemG {
        position: relative;
        -webkit-box-flex: 0;
        flex: 0 0 59.783%;
        max-width: 59.783%;
        @include touch {
          flex: 0 0 58.3333333333%;
          max-width: 58.3333333333%;
        }
      }
      &.imageItemH {
        position: relative;
        -webkit-box-flex: 0;
        flex: 0 0 40.218%;
        max-width: 40.218%;
        @include touch {
          flex: 0 0 41.6666666667%;
          max-width: 41.6666666667%;
        }
      }
      @include touch {
        padding-left: 4px;
        padding-right: 4px;
      }
    }
  }
  .btnOutline {
    @include font(16px, 36px, 3.2px, 300);
    font-family: PingFangTC-Light, Noto Sans TC, sans-serif;
    color: #000;
    border: 1px solid #000;
    margin: 0 15px;
    background: transparent;
    width: 180px;
    height: 40px;
    display: inline-block;
    text-indent: 3.2px;
    @include touch {
      margin-bottom: 2rem;
    }
    &:hover {
      color: #f5f1ed;
      background: #000;
      text-decoration: none;
    }
    &:focus {
      outline: none;
    }
  }
</style>
